<template>
  <div>

    <div class="page-container mb-1">
      <b-row>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Doanh nghiệp</label>
            <v-select
              v-model="urlQuery.bussinessId"
              :reduce="title => title.id"
              label="name"
              :options="dataAllBusiness || []"
              :placeholder="'Doanh nghiệp'"
              @input="filterBussiness"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <button-all-header
        :contentBtnAdd="'Thêm mới thông báo'"
        :arrayExcel="[]"
        :hideDowload="false"
        :hideExportFile="false"
        :hideDelete="false"
        :hideImportFile="false"
        :hideAdd="false"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="()=>{}"
        @clickExportExcel="()=>{}"
        @importFile="()=>{}"
        @clickAdd="showModalCreate"
        @search="search($event)"
      />
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'created'">
            <span>{{ props.row.created|formatDate }}</span>
          </span>
          <span v-else-if="props.column.field === 'ActionFunction'">
            <a
              :href="$serverfile + props.row.filePath"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Tải xuống'"
                icon="DownloadIcon"
                size="18"
                class="text-body"
              />
            </a>
            <span
              class="ml-2"
              @click="showModalEdit(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xem chi tiết'"
                icon="EyeIcon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />

      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />
      <modal-create
        :id="modalIdCreate"
        :title="'Chi tiết thiết bị '"
        :dataDetail="dataDetail"
        :isLoading="isLoading"
        :type="modalType"
        @hiddeModal="hiddeModal"
        @accept="handleModal"
      />
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BCol,
  BRow,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import MyPagination from '@/components/pagination/MyPagination.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreate from '@/views/management-business/notification-overtime/pages/components/ModalCreate.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BCol,
    BRow,
    FeatherIcon,
    ModalCreate,
    BFormGroup,
    VueGoodTable: () => import('@/components/table/VueGoodTable.vue'),
    MyPagination: () => import('@/components/pagination/MyPagination.vue'),
    ButtonAllHeader: () => import('@/views/common/ButtonAllHeader.vue'),
    ConfirmModal: () => import('@/components/confirm-modal/ConfirmModal.vue'),
    VSelect: () => import('vue-select'),
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      arrayExcel: [],
      formatFullName,
      columns: [
        {
          label: 'Doanh nghiệp',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'Tên file',
          field: 'fileName',
          sortable: false,
        },
        {
          label: 'Ngày tạo',
          field: 'created',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        bussinessId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      isLoading: false,
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalType: '',
      totalRecord: 0,
      dataDetail: {},
      idDetail: '',
      modalIdCreate: 'modal-id-create',
      modalIdEdit: 'modal-id-edit',
      dataList: [],
    }
  },
  computed: {
    ...mapGetters('performTraining', ['dataAllBusiness']),
  },
  created() {
    this.fetchDataComboboxAllBusiness(false)
    this.fetchData(this.urlQuery)
  },
  methods: {
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm vs xóa
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalIdCreate)
    },
    // click show modal thêm vs xóa
    hiddeModal() {
      this.$bvModal.hide(this.modalIdCreate)
    },
    // click show modal thêm vs xóa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL}${this.idDetail}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalIdCreate)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // thêm
    async handleModal(param) {
      this.isLoading = true
      const formData = new FormData()
      formData.append('Name', param.name)
      formData.append('Content', param.content)
      formData.append('IsAllBussiness', param.isAllBussiness)
      // formData.append('ListBusinessIds', param.listBusinessIds)
      formData.append('FileName', param.fileName)
      formData.append('IsSecure', param.modelFormData.isSecure)
      formData.append('formFile', param.modelFormData.files)
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.CREATE, formData).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.fetchData(this.urlQuery)
          this.isLoading = false
        }
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
    filterBussiness(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = ''
        this.fetchData(this.urlQuery)
      }
    },

  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
  #overlay {
    z-index: 10000!important;
  }
}
</style>
